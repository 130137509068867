/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
    init: (app, CookieManager) => {
        /*
        |
        | Constants
        |-----------
        */
        const
            $body = $('body'),
            $cookieBanner = $('.cookie-banner'),
            $cookieClose = $cookieBanner.find('.item-close'),
            $cookieAccept = $cookieBanner.find('.item-accept'),
            $cookieRefuse = $cookieBanner.find('.item-refuse')
        ;


        /*
		|
		| Cookie Manager
		|-----------------
		*/
        new CookieManager($cookieBanner, {
            name: 'adveris_cookie_use',
            duration: 30,
            buttons: {
                accept: $cookieAccept,
                refuse: $cookieRefuse,
                close: $cookieClose
            },
            onAccept: () => {
                console.log('accpeted')
            },
            onRefuse: () => {
                console.log('refused')
            }
        });

        $body.on('loaderEnd', () => console.log('ended'))

        /* Tabs */
        $('.onglet').hover(function () {
            $('.onglet').removeClass('active');
            $(this).addClass('active');

            $('.description').removeClass('active');
            var tab_id = $(this).attr('data-tab');
            $("#" + tab_id).addClass('active');
        });

        /* Scroll top */
        $(window).scroll(function () {
            if ($(this).scrollTop() > 200) {
                $('.scrollToTop').fadeIn();
            } else {
                $('.scrollToTop').fadeOut();
            }
        });
        $('.scrollToTop').click(function () {
            $('html, body').animate({scrollTop: 0}, 800);
            return false;
        });

        /* Ancres */
        jQuery('a[href*=\\#]').click(function (event) {
            $('html, body').animate({
                scrollTop: $($.attr(this, 'href')).offset().top - 120
            }, 1000);
            event.preventDefault();
        });

        /* Scroll top */
        $(window).scroll(function () {
            if ($(this).scrollTop() > 1) {
                $('header').addClass('active');
            } else {
                $('header').removeClass('active');
            }
        });

        /* menu mobile */
        jQuery('#mobile-menu .item-link').click(function () {
            jQuery('.item-burger').trigger('click')
        });

        /* After form sub */
        jQuery(document).ready(function () {
            jQuery(document).on('gform_confirmation_loaded', function () {
                jQuery('#contact .cms').hide();
            });
        });

    }
}
